import gql from 'graphql-tag';

export const getOneApprovedInspectionDetailQuery = gql`
  query approvedInspectionDetail($id: ID!) {
    data: approvedInspectionDetail(id: $id) {
      id
      questionCount
      questions {
        id
        question
        approved
        image {
          id
          url
          __typename
        }
      }
    }
  }
`;

export default async ({ client, params }) => {
  try {
    return client.query({
      query: getOneApprovedInspectionDetailQuery,
      variables: {
        ...params,
      },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    if (e.graphQLErrors && e.graphQLErrors.length) {
      const [error] = e.graphQLErrors;
      const message = error.message;
      throw new Error(message);
    }
    throw e;
  }
};
