import React, { cloneElement } from 'react';
import { TopToolbar, sanitizeListRestProps } from 'react-admin';

const ContractActions = ({
  organisationId,
  permanentFilter,
  resource,
  showFilter,
  filters,
  displayedFilters,
  filterValues,
  ...rest
}) => {
  return (
    <TopToolbar {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
    </TopToolbar>
  );
};

export default ContractActions;
