import React, { Fragment, useEffect, useState } from 'react';
import {
  Title,
  Create,
  required,
  fetchEnd,
  fetchStart,
  SimpleForm,
  SaveButton,
  SelectInput,
  useTranslate,
  ReferenceInput,
  useDataProvider,
  SelectArrayInput,
  FormDataConsumer,
  ReferenceArrayInput,
} from 'react-admin';
import * as R from 'ramda';
import { sideBySide } from 'styles';
import { useDispatch } from 'react-redux';
import { Send } from '@material-ui/icons';
import { useForm } from 'react-final-form';
import { TemplateToolbar } from 'Components/Toolbars';
import { booleanOptions, inviteRoleOptions } from 'utils/choices';
import {
  RemovedOrgSelectInput,
  CombinedOrgSelectInput,
} from 'Components/Fields';

const InviteToolbar = ({ hasShow, hasCreate, hasEdit, hasList, ...props }) => {
  return (
    <TemplateToolbar
      children={
        <SaveButton icon={<Send />} label="actions.invite" {...props} />
      }
      {...props}
    />
  );
};

const SanitizedForm = ({ classes, orgId }) => {
  return (
    <Fragment>
      <FormDataConsumer>
        {({ formData }) => (
          <SelectInput
            source="allowSubcontracting"
            choices={booleanOptions}
            className={classes.field}
            defaultValue={false}
            disabled={
              formData.organisation && formData.organisation.id !== orgId
            }
            label={'resources.Subcontractor.fields.allowSubcontractor'}
          />
        )}
      </FormDataConsumer>
      <SelectInput
        source="role"
        className={classes.field}
        choices={inviteRoleOptions()}
        validate={required()}
        label={'resources.Subcontractor.fields.maxRole'}
      />
    </Fragment>
  );
};

const OnBehalfOrganisationInput = ({ orgId, organisationName }) => {
  const form = useForm();

  const checkOrgVsContractor = organisationId => {
    form.change('projectIds', null);
    if (organisationId !== orgId) form.change('allowSubcontracting', false);
  };

  return (
    <ReferenceInput
      source="contractor.id"
      reference="Organisation"
      perPage={1000}
      onChange={e => checkOrgVsContractor(e.target.value)}
      // filter={{ contractorOf: orgId }}
      defaultValue={orgId}
      label={'resources.Subcontractor.fields.toWorkFor'}
      validate={required()}
    >
      {/* add the principal org to the selection */}
      <CombinedOrgSelectInput organisationId={orgId} organisationName={orgId} />
    </ReferenceInput>
  );
};

const SubcontractCreate = props => {
  const { ...rest } = props;
  const { organisationId } = props.location.state || {};

  const classes = sideBySide();
  const dispatch = useDispatch();
  const translate = useTranslate();
  const dataProvider = useDataProvider();

  const [orgId, setOrgId] = useState(organisationId);
  const [organisationName, setOrganisationName] = useState('');
  const [organisationChoices, setOrganisationChoices] = useState([]);

  const divProps = { orgId, classes, organisationName, ...rest };

  // Get org selection options
  const getOrganisationOptions = () => {
    dispatch(fetchStart());
    dataProvider('GET_LIST', 'Organisation', {
      pagination: { page: 1, perPage: 1000 },
    })
      .then(result => {
        const orgs =
          R.path(['data'], result).map(data => {
            return {
              id: data.id,
              name: data.name,
            };
          }) || [];

        setOrganisationChoices(orgs);

        const currentOrg = orgs.filter(org => org.id === organisationId);
        setOrganisationName(currentOrg[0]?.name);

        dispatch(fetchEnd());
      })
      .catch()
      .finally(() => {
        dispatch(fetchEnd());
      });
    return () => {};
  };

  // Check wether org id has been passed by navigation
  const setUp = () => {
    getOrganisationOptions();
    return () => {};
  };

  const useMountEffect = fun => useEffect(fun, []);
  useMountEffect(setUp); // run once

  return (
    <>
      <Title title={`resources.General.cmsTitle`} />
      <Create
        title={
          organisationName
            ? `${organisationName} - ${translate(
                'resources.Subcontractor.fields.inviteSubcontractor'
              )}`
            : `${translate(
                'resources.Subcontractor.fields.inviteSubcontractor'
              )}`
        }
        {...rest}
      >
        <SimpleForm
          onSubmit={e => {
            e.preventDefault();
          }}
          submitOnEnter={false}
          redirect={`/Subcontractor`}
          toolbar={<InviteToolbar {...props} />}
        >
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <ReferenceInput
                {...rest}
                source="organisationId"
                reference="Organisation"
                perPage={1000}
                fullWidth
                defaultValue={orgId}
                onChange={event => {
                  const orgName = organisationChoices.find(
                    it => it.id === event.target.value
                  ).name;

                  setOrgId(event.target.value);
                  setOrganisationName(orgName);
                }}
                validate={required()}
                label={'resources.Site.fields.organisation'}
              >
                <SelectInput
                  label="resources.Site.fields.organisation"
                  source="organisationId"
                  perPage={1000}
                  choices={organisationChoices}
                  validate={required()}
                />
              </ReferenceInput>
            )}
          </FormDataConsumer>
          {/* custom field so we can use the useForm hook */}
          <OnBehalfOrganisationInput {...divProps} />
          {/* {/* remove the principal organisation from the selection */}
          <ReferenceInput
            source="subcontractorId"
            reference="Organisation"
            validate={required()}
            perPage={1000}
            label={'resources.Subcontractor.fields.inviteOrg'}
          >
            {/* remove the principal organisation from the selection */}
            <RemovedOrgSelectInput
              label={'resources.Subcontractor.fields.inviteOrg'}
              choices={organisationChoices}
              organisationId={organisationId}
              organisationName={organisationName}
            />
          </ReferenceInput>
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <ReferenceArrayInput
                {...rest}
                source="projectIds"
                reference="Project"
                perPage={1000}
                filter={{
                  principalOrganisationId: orgId,
                  organisationId: formData.organisation?.id,
                }}
                fullWidth
                validate={required()}
                label={'resources.Subcontractor.fields.toProjects'}
              >
                <SelectArrayInput optionText="name" />
              </ReferenceArrayInput>
            )}
          </FormDataConsumer>
          <SanitizedForm {...divProps} />
        </SimpleForm>
      </Create>
    </>
  );
};

export default SubcontractCreate;
