import React from 'react';
import { TopToolbar, sanitizeListRestProps, CreateButton } from 'react-admin';

const TowerActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter = false, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  ...rest
}) => {
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

TowerActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
};

export default TowerActions;
