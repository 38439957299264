import React from 'react';
import { DeleteButton, SaveButton, Toolbar } from 'react-admin';
import { toolbarStyles } from 'styles';

const SanitizedDeleteButton = ({
  onSave,
  confirmContent,
  confirmTitle,
  ...rest
}) => (
  <DeleteButton
    label="DEACTIVATE"
    {...rest}
    confirmTitle={confirmTitle}
    confirmContent={confirmContent}
  />
);

const ReactivateUserToolbar = props => {
  const {
    hideDelete = true,
    className,
    onSave,
    redirect,
    deleteRedirect = 'list',
    ...rest
  } = props;
  const classes = toolbarStyles();
  const userDetail = rest?.record?.email;
  const confirmTitle = `Deactivate user ${userDetail}`;
  const confirmContent = `Are you sure you want to deactivate this user?`;
  return (
    <Toolbar className={classes.root} {...rest}>
      <SaveButton label="REACTIVATE" redirect={redirect} />
      {hideDelete ? null : (
        <SanitizedDeleteButton
          confirmContent={confirmContent}
          confirmTitle={confirmTitle}
          redirect={deleteRedirect}
          undoable={false}
        />
      )}
    </Toolbar>
  );
};

export default ReactivateUserToolbar;
