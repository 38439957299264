import React, { cloneElement } from 'react';
import { TopToolbar, sanitizeListRestProps } from 'react-admin';
import { LinkButton } from 'Components/Buttons';
import { Add } from '@material-ui/icons';

const SubcontractorActions = ({
  organisationId,
  permanentFilter,
  resource,
  showFilter,
  filters,
  displayedFilters,
  filterValues,
  ...rest
}) => {
  return (
    <TopToolbar {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {organisationId && (
        <LinkButton
          name={'actions.invite'}
          pathname={'/Subcontractor/create'}
          state={{
            organisationId,
            record: {
              contractor: {
                id: organisationId,
              },
            },
          }}
          icon={<Add />}
        />
      )}
    </TopToolbar>
  );
};

export default SubcontractorActions;
